@import "~react-dropzone-uploader/dist/styles.css";
@import "~react-bulma-components/src/index.sass";
@import "~bulma-badge/src/sass/index.sass";

.table td, .table th {
    word-break: break-word; }

.dzu-dropzone {
    overflow: auto; }

.dzu-inputLabelWithFiles {
    align-self: center;
    margin-bottom: 1em;
    margin-left: 0; }

.dzu-submitButtonContainer {
    width: 80%;
    .dzu-submitButton {
        width: 100%;
        border-radius: 290486px;
        background: #3273dc; } }

.title {
    font-size: 1.4em;
    font-weight: 300; }

.bar-group .name-label {
    text-anchor: end;
    font-weight: 300;
    font-size: 1em;
    fill: #333; }

.bar-group .votes-rect {
  fill: #209cee; }

.bar-group .votes-rect-has-vote {
  fill: #ffdd57; }

.bar-group .all-value {
    text-anchor: end;
    font-weight: 900;
    &text.user-value {
      fill: rgb(64, 64, 64); } }

.bar-group .no-value {
    fill: #eee;
    font-weight: 900; }

.bar-group .one-value {
    fill: #eee;
    font-weight: 900; }

.bar-group {
    fill: #222; }

.container .bar-group:hover .name-label {
    font-weight: 400; }

.container .bar-group:hover {
    font-size: 1.2em; }

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed; }

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em; }

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em; }

table th,
table td {
  padding: .625em;
  text-align: center; }

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase; }

@media screen and (max-width: 1000px) {
  table {
    border: 0; }

  table caption {
    font-size: 1.3em; }

  table thead {
    border: none;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em; }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right; }

  table td:not(.exclude)::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase; }

  table td:last-child {
    border-bottom: 0; } }
